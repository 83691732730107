<template>
    <div id="target-campaign-page">
        <div class='container-fluid p-3'>
            <div class='row'>
                <div class="col-xl-2 col-md-3 col-sm-12">
                    <InputText :placeholder="$t('campaignCode')" v-model.trim="campaignCodeLocal"/>
                </div>
                <div class="col-xl-2 col-md-3 col-sm-12">
                    <InputText :placeholder="$t('campaignName')" v-model.trim="campaignNameLocal"/>
                </div>
                <div class="col-xl-2 col-md-3 col-sm-12">
                    <InputSelect :placeholder="$t('status')" v-model="statusLocal" :options="$constant.CAMPAIGN.listStatus"/>
                </div>
                <div class="col-xl-2 col-md-3 col-sm-12">
                    <Daterangepicker :placeholder="$t('fromDateToDate')" v-model="fromDateToDateLocal"/>
                </div>
                <div class="col-xl-3 col-md-3 col-sm-12">
                    <button @click="searchData()" class="btn btn-primary mx-2">
                        <span>{{ $t('search') }}</span>
                    </button>
                    <button @click="clearSearch()" class="btn btn-secondary mx-2">
                        <span>{{ $t('clearFilter') }}</span>
                    </button>
                </div>
            </div>
            <div class='row'>
                <div class='col-lg-12'>
                    <div class="card">
                        <div class="my-3 d-flex justify-content-between align-items-center">
                            <h5 class='card-title m-0'>{{ $t('sum') }} {{ numberFormat(entries?.length) }}</h5>
                            <div>
                                <button @click="exportData()" class="btn btn-outline-secondary mx-2">
                                    <span>{{ $t('exportData') }}</span>
                                </button>
                                <AppPermission :keyPerm="$constant.PERMISSIONS.PER_TARGET_CAMPAIGN_ADMIN" :actionPerm="$constant.PERMISSIONS.PER_ACTION_CREATE">
                                    <button type='submit' class='btn btn-primary mx-2'>
                                        <router-link :to="this.$constant.ROUTER.createTargetCampaign" class="text-white">
                                            <i class="fa fa-plus mx-2"></i>
                                            <span class="mx-2">
                                                {{ $t('createNewCampaign') }}
                                            </span>
                                        </router-link>
                                    </button>
                                </AppPermission>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="thead-light border">
                                    <tr>
                                        <th scope="col">{{ $t('campaignName') }}</th>
                                        <th scope="col">{{ $t('status') }}</th>
                                        <th scope="col">{{ $t('time') }}</th>
                                        <th scope="col">{{ $t('partner') }}</th>
                                        <th scope="col">{{ $t('product') }}</th>
                                        <th scope="col">{{ $t('percentCommission') }}</th>
                                        <th scope="col">{{ $t('action') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable">
                                    <template v-for="(entry, index) in entries" :key="index">
                                        <tr>
                                            <td>
                                                <p>{{ entry.name }}</p>
                                                <p>
                                                    {{ $t('code') }}: {{ entry.code }}
                                                </p>
                                            </td>
                                            <td>
                                                <span
                                                    :class="getClassForTargetCampaignStatus($filters.formatStatusCampaignPartner(entry.status,entry.start_time, entry.end_time))">
                                                    {{ $filters.formatStatusCampaignPartner(entry.status,entry.start_time, entry.end_time) }}
                                                </span>
                                            </td>
                                            <td>{{ $filters.formatStartTimeAndEndTime(entry.start_time, entry.end_time) }}</td>
                                            <td>
                                                <div class="d-flex align-items-center" v-if="entry.target_campaign_partners && entry.target_campaign_partners.length > 2">
                                                    <template v-for="(partner, index) in entry.target_campaign_partners" :key="index">
                                                        <span v-if="index < 2" >
                                                            {{ partner.name + ', '}} 
                                                        </span>
                                                    </template>
                                                    <div class="count-product mx-2">+{{ entry.target_campaign_partners.length - +2 }}</div>
                                                </div>
                                                <div v-else>
                                                    <template v-for="(partner, index) in entry.target_campaign_partners" :key="index">
                                                        <span v-text="index + 1 < entry.target_campaign_partners?.length ? partner.name + ', ' : partner.name"></span>
                                                    </template>
                                                </div>

                                            </td>
                                            <td class="info-products">
                                                <div class="image d-flex align-items-center" v-if="entry.products && entry.products_count > 2">
                                                    <template v-for="(product, index) in entry.products" :key="index">
                                                        <img v-if="index <= 1" :src="product.main_image ?? image_default"/>
                                                    </template>
                                                    <div class="count-product mx-2">+{{ entry.products_count - +2 }}</div>
                                                </div>
                                                <div v-else class="image">
                                                    <img v-for="product in entry.products" :key="product.id" :src="product.main_image ?? image_default"/>
                                                </div>
                                            </td>
                                            <td>{{ $filters.formatRangerRate(entry.max_rate_target_campaign_product, entry.min_rate_target_campaign_product) }}</td>
                                            <td class="color_link nowrap">
                                                <span class="mx-1" role="button" @click="showDetail(entry.id)">
                                                    <span class="icon_action" :titleCustom="$t('manageCampaign')"><img :src="icon_campaign_manage"/></span>
                                                </span>
                                                <span class="mx-1" role="button" @click="showDetail(entry.id, 'performance')">
                                                    <span class="icon_action" :titleCustom="$t('seePerformance')"><img :src="icon_view_performance"/></span>
                                                </span>
                                                <AppPermission :keyPerm="$constant.PERMISSIONS.PER_TARGET_CAMPAIGN_ADMIN" :actionPerm="$constant.PERMISSIONS.PER_ACTION_DELETE">
                                                    <span class="mx-1" role="button" @click="showModalStop(entry.id, entry.end_time, entry.start_time)" v-if="checkCanStopCampaign(entry, $filters.formatStatusCampaignPartner(entry.status,entry.start_time, entry.end_time))">
                                                        <span class="icon_remove" :titleCustom="$t('stopCampaign')"><img :src="icon_remove"/></span>
                                                    </span>
                                                </AppPermission>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-if="!entries || !entries.length">
                                        <tr class="text-center">
                                            <td colspan="7">
                                                <span>{{ $t('noData') }}</span>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Paginate :value="paginate" :record="record"/>
        </div>
        <Loading :loading="isLoading" />
        <ModalConfirmStop
            :title="$t('titleStopCampaign')"
            :content="this.contentStopCampaign"
            ref="ModalConfirmStop"
            @confirm="stopCampaign"
        />
        </div>
</template>

<script>
import { numberFormat, getClassForTargetCampaignStatus, checkPermission } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import Paginate from '@/components/Paginate.vue';
import InputText from '@/components/Form/InputText.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
import Daterangepicker from '@/components/Daterangepicker.vue';
import image_default from '../../../public/images/default-image.jpg';
import ModalConfirmStop from '@/components/Modal/ModalConfirm.vue';
import i18n from '@/locales/i18nHelper';
import toastr from 'toastr';

import store from "@/store";
import moment from 'moment';
import icon_remove from '../../../public/images/icon_remove.png'
import icon_view_performance from '../../../public/images/icon_view_performance.png'
import icon_campaign_manage from '../../../public/images/icon_campaign_manage.png'
import AppPermission from '@/components/AppPermission.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'TargetCampaign',
    setup() {
        return { numberFormat, getClassForTargetCampaignStatus, checkPermission };
    },
    components: {
        Loading,
        InputText,
        Paginate,
        InputSelect,
        Daterangepicker,
        ModalConfirmStop,
        AppPermission
    },
    created() {
        this.getCampaignsTarget();
    },
    data() {
        return {
            isLoading: false,
            image_default: image_default,
            record: this.$constant.PAGE.record,
            paginate: {
                totalRecord: 0,
                currentPage: 1,
                lastPage: 0
            },
            filters: {
                tab: 1,
            },
            pID: null,
            entries: [],
            campaignCodeLocal: null,
            campaignCode: null,
            campaignNameLocal: null,
            campaignName: null,
            statusLocal: null,
            status: null,
            fromDateToDateLocal: null,
            fromDateToDate: null,
            campaign_id: null,
            campaignName: null,
            endTimePlan: null,
            contentStopCampaign: null,
            end_campaign: false,
            icon_remove: icon_remove,
            icon_view_performance: icon_view_performance,
            icon_campaign_manage: icon_campaign_manage,
        }
    },
    computed: {
        ...mapGetters({
            isLoading: 'isLoading',
            loadedPermission: 'loadedPermission'
        }),
    },
    updated(){
        if(!this.isLoading && this.loadedPermission){
            if(!this.checkPermission(this.$constant.PERMISSIONS.PER_TARGET_CAMPAIGN_ADMIN, this.$constant.PERMISSIONS.PER_ACTION_LIST)){
                this.$router.push({name: this.$constant.ROUTER.dashboardName});
            }
        }
    },
    watch: {
        'paginate.currentPage': function() {
            this.getCampaignsTarget();
        },
    },
    
    methods: {
        showPerformance(campaign_id) {
            this.$router.push({name: this.$constant.ROUTER.performanceDetailCampaignTargetName, query: {campaign_id: campaign_id}});
        },
        showDetail(CampaignId, tab = null) {
            this.$router.push({name: this.$constant.ROUTER.detailTargetCampaignName, query: {id: CampaignId, tab: tab}});
        },
        async stopCampaign() {              
            const res = await store.dispatch('campaignStore/stopCampaignTarget', {
                end_time: this.endTimePlanSub,
                campaign_id: this.campaign_id,
                end_campaign: this.end_campaign
            });
            if (res && res.status) {
                toastr.success(this.$t('msgRemoveSuccess'));
            } else {
                toastr.error(this.$t('msgRemoveError'));
            }
            this.getCampaignsTarget();
        },
        showModalStop(campaign_id, end_time, start_time) {
            const now = moment().format('YYYY-MM-DD HH:mm:ss');
            this.campaign_id = campaign_id;
            this.end_campaign = false;
            if(moment(start_time, 'YYYY-MM-DD HH:mm:ss').isAfter(now)){
                
                this.endTimePlanSub = end_time;
                this.end_campaign = true;
                this.contentStopCampaign = this.$t('confirmStopThisCampaign');
            }else{
                let time = moment().add(7, 'days').format('HH:mm');
                let date = moment().add(7, 'days').format('DD/MM/YYYY');
                let endTimePlan = null;
                let endTimePlanSub = null;
                if (!end_time) {
                    endTimePlan = moment().add(7, 'days').format('HH:mm ' + i18n.global.t("date") + 'DD/MM/YYYY');
                    endTimePlanSub = moment().add(7, 'days').format('YYYY-MM-DD HH:mm');
                    this.end_campaign = true;
                } else {
                    let endTimeOrigin = moment(end_time);
                    if (moment().add(7, 'days') < endTimeOrigin) {
                        endTimePlan = moment().add(7, 'days').format('HH:mm' + i18n.global.t("date") + 'DD/MM/YYYY');
                        endTimePlanSub = moment().add(7, 'days').format('YYYY-MM-DD HH:mm');
                    } else {
                        // endTimePlanSub = moment(end_time).format('YYYY-MM-DD HH:mm');
                        endTimePlan = moment(end_time).format('HH:mm'+ i18n.global.t("date") +'DD/MM/YYYY');
                        let messages = i18n.global.t('contentStopCampaignStart') + endTimePlan;
                        toastr.error(messages);
                        return;
                    }
                }            
                this.endTimePlan = endTimePlan;
                this.endTimePlanSub = endTimePlanSub;
                this.contentStopCampaign = this.$t('contentStopCampaign', {time: time, date: date});
            }
            this.$refs.ModalConfirmStop.show();
        },
        async getCampaignsTarget() {
            const res = await store.dispatch('campaignStore/getCampaignsTarget', {
                currentPage: this.paginate.currentPage,
                record: this.record,
                status: this.status,
                campaignCode: this.campaignCode,
                fromDateToDate: this.fromDateToDate,
                campaignName: this.campaignName,
            });
            if (res && res.data) {
                this.entries = res.data;
                this.paginate.currentPage = res.current_page;
                this.paginate.lastPage = res.last_page;
                this.paginate.totalRecord = res.total;
            }
        },
        clearSearch() {
            this.campaignCode = null;
            this.campaignCodeLocal = null;
            this.status = null;
            this.statusLocal = null;
            this.paginate.currentPage = 1;
            this.fromDateToDate = null;
            this.fromDateToDateLocal = null;
            this.campaignNameLocal = null,
            this.campaignName = null,
            this.getCampaignsTarget();
        },
        searchData() {
            this.campaignCode = this.campaignCodeLocal;
            this.status = this.statusLocal;
            this.fromDateToDate = this.fromDateToDateLocal;
            this.campaignName = this.campaignNameLocal;
            this.getCampaignsTarget();
        },
        async exportData(){
            const res = await store.dispatch('campaignStore/exportTargetCampaign', {
                status: this.status,
                campaignCode: this.campaignCode,
                fromDateToDate: this.fromDateToDate,
                campaignName: this.campaignName,
            });
            if(res){
                this.$store.dispatch('setShowModalExport', true)
            }
        },
        checkCanStopCampaign(entry, status){
            const seven_day_after = moment().add(7, 'days').format('YYYY-MM-DD HH:mm');
            if(status === this.$t('campaignStatusActive') && moment(entry.end_time, 'YYYY-MM-DD HH:mm').isBefore(seven_day_after)){
                return false;
            }
            const listStatus = [
                this.$t('campaignStatusDraft'),
                this.$t('campaignStatusClose'),
                this.$t('campaignEnd'),
            ]
            if(listStatus.includes(status)){
                return false;
            }
            return true;
        }
    },
};
</script>

<style lang="scss" scoped>
#target-campaign-page{
    .table-responsive{
        .customtable {
            .info-products{
                .image img{
                    // height: 60px !important;
                    width: 50px;
                    transform: scale(1);
                    margin: 1px;
                }
                p{
                    margin-bottom: 0px;
                }
            }
            .count-product {
                width: 35px;
                height: 35px;
                border: 1px solid #dee2e6;
                border-radius: 5px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #dee2e6;
            }
        }
    }
}
</style>