<template>
    <div id="dashboard-page">
        <div class='container-fluid p-3'>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='d-md-flex align-items-center'>
                                <div>
                                    <h4 class='card-title'>{{ $t('filterTime') }}</h4>
                                </div>
                            </div>
                            <div class='row'>
                                <div class='col-xl-8 col-md-8 col-sm-12 d-flex align-items-center'>
                                    <span v-html="$t('timeSeeData')"></span>

                                    <InputMonth class="mx-2" v-model="dateTimeSearch" @update:modelValue="changeTime" />
                                    <!-- <Daterangepicker :placeholder="$t('timeOverview')" class="mx-2"
                                        v-model="dateTimeSearch" :defaultDate="dateTimeSearch" /> -->
                                    <button class="btn btn-secondary" @click="clearData">
                                        <span>{{ $t('clearFilter') }}</span>
                                    </button>
                                </div>
                                <div class='col-xl-4 col-md-4 col-sm-12 text-right'>
                                    {{ $t('lastUpdated') }} <span
                                        v-text="this.last_update ? formatDateCTV(this.last_update, 'HH:mm DD/MM/YYYY') : null"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='d-md-flex align-items-center'>
                                <div>
                                    <h4 class='card-title'>{{ $t('indicators') }}</h4>
                                </div>
                            </div>
                            <div class='row'>
                                <div class="row mt-3">
                                    <div class="col-md-6 col-lg-4 col-xlg-4">
                                        <div class="card card-hover">
                                            <div class="box border cursor-pointer"
                                                @click="changeType(this.$constant.PAGE.TYPE_CHART_CLICK, 'clicks')">
                                                <span>{{ $t('numberClick') }}</span>
                                                <h3 class="text-dark">{{
                                                    numberFormat(clicks?.clicks) }}</h3>
                                                <div class="d-flex justify-content-between">
                                                    <span>{{ $t('compareTheSamePeriod') }}</span>
                                                    <span v-if="clicks?.rate_clicks >= 0" class="text-success">▲{{
                                                        clicks?.rate_clicks }}%</span>
                                                    <span v-else class="text-danger">&#9660;{{
                                                        numberFormat($filters.formatNegativeNumber(clicks?.rate_clicks)) }}%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4 col-xlg-4">
                                        <div class="card card-hover">
                                            <div class="box border cursor-pointer"
                                                @click="changeType(this.$constant.PAGE.TYPE_CHART_PRODUCTS_SOLD, 'productSold')">
                                                <span>{{ $t('productSold') }}</span>
                                                <h3 class="text-dark">{{
                                                    numberFormat(product?.products_sold) }}</h3>
                                                <div class="d-flex justify-content-between">
                                                    <span>{{ $t('compareTheSamePeriod') }}</span>
                                                    <span v-if="product?.rate_products_sold >= 0" class="text-success">▲{{
                                                        product?.rate_products_sold }}%</span>
                                                    <span v-else class="text-danger">&#9660;{{
                                                        numberFormat($filters.formatNegativeNumber(product?.rate_products_sold)) }}%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-4 col-xlg-4">
                                        <div class="card card-hover">
                                            <div class="box border cursor-pointer"
                                                @click="changeType(this.$constant.PAGE.TYPE_CHART_ORDER, 'order')">
                                                <span>{{ $t('order') }}</span>
                                                <h3 class="text-dark">{{
                                                    numberFormat(order?.quantity_order) }}</h3>
                                                <div class="d-flex justify-content-between">
                                                    <span>{{ $t('compareTheSamePeriod') }}</span>
                                                    <span v-if="order?.rate_quantity_order >= 0" class="text-success">▲{{
                                                        order?.rate_quantity_order }}%</span>
                                                    <span v-else class="text-danger">&#9660;{{
                                                        numberFormat($filters.formatNegativeNumber(order?.rate_quantity_order)) }}%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-3 col-xlg-3">
                                        <div class="card card-hover">
                                            <div class="box border cursor-pointer"
                                                @click="changeType(this.$constant.PAGE.TYPE_CHART_COMMISSION, 'estCommission')">
                                                <span>{{ $t('estCommission') }}</span>
                                                <h3 class="text-dark">{{
                                                    numberFormat(est?.commission) }}đ</h3>
                                                <div class="d-flex justify-content-between">
                                                    <span>{{ $t('compareTheSamePeriod') }}</span>
                                                    <span v-if="est?.rate_commission >= 0" class="text-success">▲{{
                                                        est?.rate_commission }}%</span>
                                                    <span v-else class="text-danger">&#9660;{{
                                                        numberFormat($filters.formatNegativeNumber(est?.rate_commission)) }}%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-3 col-xlg-3">
                                        <div class="card card-hover">
                                            <div class="box border cursor-pointer"
                                                @click="changeType(this.$constant.PAGE.TYPE_CHART_SALES, 'orderValues')">
                                                <span>{{ $t('orderValues') }}</span>
                                                <h3 class="text-dark">{{
                                                    numberFormat(amount?.amount_sale) }}đ</h3>
                                                <div class="d-flex justify-content-between">
                                                    <span>{{ $t('compareTheSamePeriod') }}</span>
                                                    <span v-if="amount?.rate_amount_sale >= 0" class="text-success">▲{{
                                                        amount?.rate_amount_sale }}%</span>
                                                    <span v-else class="text-danger">&#9660;{{
                                                        numberFormat($filters.formatNegativeNumber(amount?.rate_amount_sale)) }}%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-3 col-xlg-3">
                                        <div class="card card-hover">
                                            <div class="box border cursor-pointer"
                                                @click="changeType(this.$constant.PAGE.TYPE_CHART_ALL_BUYER_NEW, 'newBuyer')">
                                                <span>{{ $t('newBuyer') }}</span>
                                                <h3 class="text-dark">{{ numberFormat(new_customer?.new_buyer)
                                                    }}</h3>
                                                <div class="d-flex justify-content-between">
                                                    <span>{{ $t('compareTheSamePeriod') }}</span>
                                                    <span v-if="new_customer?.rate_new_buyer >= 0" class="text-success">▲{{
                                                        new_customer?.rate_new_buyer }}%</span>
                                                    <span v-else class="text-danger">&#9660;{{
                                                        numberFormat($filters.formatNegativeNumber(new_customer?.rate_new_buyer)) }}%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-3 col-xlg-3">
                                        <div class="card card-hover">
                                            <div class="box border cursor-pointer"
                                                @click="changeType(this.$constant.PAGE.TYPE_CHART_ALL_BUYER, 'allBuyer')">
                                                <span>{{ $t('allBuyer') }}</span>
                                                <h3 class="text-dark">{{
                                                    numberFormat(all_customer?.all_buyers) }}</h3>
                                                <div class="d-flex justify-content-between">
                                                    <span>{{ $t('compareTheSamePeriod') }}</span>
                                                    <span v-if="all_customer?.rate_all_buyers >= 0" class="text-success">▲{{
                                                        all_customer?.rate_all_buyers }}%</span>
                                                    <span v-else class="text-danger">&#9660;{{
                                                        numberFormat($filters.formatNegativeNumber(all_customer?.rate_all_buyers)) }}%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class="row">
                                <div class="col-xl-12 col-md-12 col-sm-12 mb-4">
                                    <LineChart :title="nameChart" :labelValues="this.labels"
                                        :datasetValues="this.datasets" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='d-md-flex align-items-center'>
                                <div>
                                    <h4 class='card-title'>{{ $t('top_5_products') }}</h4>
                                </div>
                            </div>
                            <ul class='nav nav-tabs' role='tablist'>
                                <li class='nav-item'>
                                    <a class='nav-link active' data-bs-toggle='tab' @click="changeTab('quantity')"
                                        href='#open' role='tab'>
                                        <span class='hidden-sm-up'></span>
                                        <span class='hidden-xs-down'>{{ $t('highest_sales_volume') }}</span>
                                    </a>
                                </li>
                                <li class='nav-item'>
                                    <a class='nav-link' data-bs-toggle='tab' @click="changeTab('amount')" href='#target'
                                        role='tab'>
                                        <span class='hidden-sm-up'></span>
                                        <span class='hidden-xs-down'>{{ $t('highest_estimated_commission') }}</span>
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <!-- Open Campaign Content -->
                                <div class="tab-pane fade show active" id="open">
                                    <div class="table-responsive mt-2">
                                        <table class="table table-index table-bordered">
                                            <thead class="thead-light border">
                                                <tr>
                                                    <th scope="col">{{ $t('rank') }}</th>
                                                    <th scope="col">{{ $t('productInformation') }}</th>
                                                    <th scope="col">{{ $t('sales') }}</th>
                                                    <th scope="col">{{ $t('numberOfProductSold') }}</th>
                                                    <th scope="col">{{ $t('order') }}</th>
                                                    <th scope="col">{{ $t('estCommission') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody class="customtable">
                                                <template v-if="this.top_products.length"
                                                    v-for="(product, index) in this.top_products" :key="product.code">
                                                    <tr>
                                                        <td><span class="text-danger font-weight-bold">{{ index + 1
                                                                }}</span></td>
                                                        <td>
                                                            <div class="info-products d-flex">
                                                                <div class="image">
                                                                    <img v-if="product?.product"
                                                                        :src="product?.product.main_image" />
                                                                    <img v-else :src="image_default" />
                                                                </div>
                                                                <div class="product mx-1">
                                                                    <a class="sidebar-link has-arrow waves-effect waves-dark"
                                                                        target="_blank"
                                                                        :href="urlPharmaProduct(product?.product?.slug)"
                                                                        aria-expanded="false">
                                                                        {{ product?.product.name }}
                                                                    </a>
                                                                    <p>
                                                                        {{ $t('skuId') }}: {{
                                                                        product?.product?.product_variant_max_price?.sku
                                                                        }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{{ numberFormat(product?.sum_amount) }}</td>
                                                        <td>{{ numberFormat(product?.sum_quantity) }}</td>
                                                        <td>{{ numberFormat(product?.count_order) }}</td>
                                                        <td>{{ numberFormat(product?.commissions) }}</td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                        <div id="noData" v-if="this.top_products?.length == 0">
                                            <span>{{ $t('noData') }}</span>
                                        </div>
                                    </div>
                                </div>

                                <!-- Target Campaign Content -->
                                <div class="tab-pane fade" id="target">
                                    <div class="table-responsive mt-2">
                                        <table class="table table-index table-bordered">
                                            <thead class="thead-light border">
                                                <tr>
                                                    <th scope="col">{{ $t('rank') }}</th>
                                                    <th scope="col">{{ $t('productInformation') }}</th>
                                                    <th scope="col">{{ $t('sales') }}</th>
                                                    <th scope="col">{{ $t('numberOfProductSold') }}</th>
                                                    <th scope="col">{{ $t('order') }}</th>
                                                    <th scope="col">{{ $t('estCommission') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody class="customtable">
                                                <template v-if="this.top_amount.length"
                                                    v-for="(product, index) in this.top_amount" :key="product.code">
                                                    <tr>
                                                        <td><span class="text-danger font-weight-bold">{{ index + 1
                                                                }}</span></td>
                                                        <td>
                                                            <div class="info-products d-flex">
                                                                <div class="image">
                                                                    <img v-if="product?.product"
                                                                        :src="product?.product.main_image" />
                                                                    <img v-else :src="image_default" />
                                                                </div>
                                                                <div class="product mx-1">
                                                                    <a class="sidebar-link has-arrow waves-effect waves-dark"
                                                                        target="_blank"
                                                                        :href="urlPharmaProduct(product?.product?.slug)"
                                                                        aria-expanded="false">
                                                                        {{ product?.product.name }}
                                                                    </a>
                                                                    <p>
                                                                        {{ $t('skuId') }}: {{
                                                                        product?.product?.product_variant_max_price?.sku
                                                                        }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{{ numberFormat(product?.sum_amount) }}</td>
                                                        <td>{{ numberFormat(product?.sum_quantity) }}</td>
                                                        <td>{{ numberFormat(product?.count_order) }}</td>
                                                        <td>{{ numberFormat(product?.commissions) }}</td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                        <div id="noData" v-if="this.top_products?.length == 0">
                                            <span>{{ $t('noData') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class='row' v-if="checkShowRankContributors()">
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='d-md-flex align-items-center'>
                                <div>
                                    <h4 class='card-title'>{{ $t('rank') + ' ' + 'CTV' }}</h4>
                                </div>
                            </div>

                            <div class="tab-content">
                                <!-- Open Campaign Content -->
                                <div class="tab-pane fade show active" id="open">
                                    <div class="table-responsive mt-2">
                                        <table class="table table-index table-bordered">
                                            <thead class="thead-light border">
                                                <tr>
                                                    <th scope="col">{{ $t('rank') }}</th>
                                                    <th scope="col">{{ $t('collaboratorName') }}</th>
                                                    <th scope="col">{{ $t('conversions') }}</th>
                                                    <th scope="col">{{ $t('sales') }}</th>
                                                    <th scope="col">{{ $t('estCommission') }}</th>
                                                </tr>
                                            </thead>
                                            <tbody class="customtable">
                                                <template v-if="this.top_contributors.length"
                                                    v-for="(contributor, index) in this.top_contributors"
                                                    :key="contributor.contributor_id">
                                                    <tr>
                                                        <td><span class="text-danger font-weight-bold">{{ index + 1
                                                                }}</span></td>
                                                        <td>{{ contributor.name }}</td>
                                                        <td>{{ numberFormat(contributor?.count_order) }}</td>
                                                        <td>{{ numberFormat(contributor?.sum_amount) }}</td>
                                                        <td>{{ numberFormat(contributor?.sum_commission) }}</td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                        <div id="noData" v-if="this.top_contributors?.length == 0">
                                            <span>{{ $t('noData') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loading :loading="isLoading" />
    </div>
</template>

<script>
import Daterangepicker from '@/components/Daterangepicker.vue'
import { numberFormat, numberFormatABS, formatDateCTV } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import LineChart from '@/components/ChartJS/LineChart.vue';
import i18n from '@/locales/i18nHelper'
import InputMonth from '@/components/Form/InputMonth.vue'
import { mapGetters } from 'vuex';

export default {
    name: 'DashboardContributor',
    setup() {
        return { numberFormat, numberFormatABS, formatDateCTV };
    },
    components: {
        Daterangepicker,
        Loading,
        LineChart,
        InputMonth
    },
    created() {
        this.statistical();
        this.getDataBarChartContributor(true);
        // this.getTopProductsForContributor();
        // this.getTopContributorDashboard();
    },
    data() {
        return {
            dateTimeSearch: moment().format('YYYY-MM'),
            typeSrc: this.$constant.PAGE.TYPE_CHART_COMMISSION,
            nameChart: i18n.global.t('estCommission'),
            isLoading: false,
            datasets: [],
            labels: [],

            order: null,
            product: null,
            est: null,
            amount: null,
            new_customer: null,
            all_customer: null,
            top_products: [],
            top_amount: [],
            top_contributors: [],
            clicks: null,
            type: 'quantity',
            last_update: null
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/userInfo',
        })
    },
    watch: {
        dateTimeSearch() {
            this.statistical();
            this.getDataBarChartContributor();
            this.getTopProductsForContributor();
            this.getTopContributorDashboard();
        }
    },
    methods: {
        async changeTab(type) {
            this.type = type; 
            await this.getTopProductsForContributor();
        },
        clearData() {
            this.dateTimeSearch = moment().format('YYYY-MM');
        },
        changeType(type, nameChart){
            this.typeSrc = type;
            this.nameChart = i18n.global.t(nameChart)
            this.getDataBarChartContributor();
        },
        async statistical() {
            const res = await this.$store.dispatch('performanceStore/statistical', {
                dateTimeSearch: this.dateTimeSearch,
            });
            if (res) {
                this.order = res.order;
                this.product = res.product;
                this.est = res.est
                this.amount = res.amount
                this.new_customer = res.new_customer
                this.all_customer = res.all_customer
                this.clicks = res.clicks
                this.last_update = res.last_update
            }
        },
        showChart(typeSrc, nameChart) {
            this.typeSrc = typeSrc;
            this.nameChart = nameChart;
            this.getDataBarChartContributor();
        },
        async getDataBarChartContributor(status) {
            if (!this.dateTimeSearch) {
                return;
            }
            const res = await this.$store.dispatch('performanceStore/getDataBarChartContributor', {
                dateTimeSearch: this.dateTimeSearch,
                typeSrc: this.typeSrc
            });
            if (res) {
                this.datasets = res.datasets
                this.labels = res.labels
            }
            if (status) {
                this.getTopProductsForContributor();
                this.getTopContributorDashboard();
            }
        },

        async getTopProductsForContributor() {
            const res = await this.$store.dispatch('performanceStore/getTopProductsForContributor', {
                dateTimeSearch: this.dateTimeSearch,
                type: this.type
            });
            if (res) {
                if(this.type == 'quantity') {
                    this.top_products = res;
                }else {
                    this.top_amount = res;
                }
            }
        },

        async getTopContributorDashboard() {
            const res = await this.$store.dispatch('performanceStore/getTopContributorDashboard', {
                dateTimeSearch: this.dateTimeSearch,
            });
            if (res) {
               this.top_contributors = res;
            }
        },

        checkShowRankContributors(){
            if(!this.user){
                return false;
            }
            let partners = this.$constant.PARTNER_HIDE_RANK_CONTRIBUTORS;
            if(this.user?.type == this.$constant.PERMISSIONS.TYPE_CONTRIBUTOR && partners.includes(this.user?.partner_id)){
                return false;
            }
            return true;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/dashboard.scss";
</style>