<template>
    <div class='modal fade' ref="ModalApproveMultiPayment" id='ModalApproveMultiPayment' aria-labelledby='ModalApproveMultiPayment'
        aria-hidden='true' data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-md'>
            <div class='modal-content'>
                <div class='modal-header'>
                    <h1 class='modal-title fs-5'>{{ $t('confirm') }}</h1>
                    <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                </div>
                <div class='modal-body'>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12'>
                            <div>
                                <label>{{ $t('contentConfirmApprovePayment') }}</label>
                            </div>
                        </div>
                    </div>
                    <div class='row mt-3'>
                        <div class='d-flex justify-content-center mt-3'>
                            <button class='btn btn-secondary mx-2' data-bs-dismiss='modal' aria-label='Close'>
                                {{ $t('cancel') }}
                            </button>
                            <button class='btn btn-warning mx-2' @click="confirm">
                                <span>
                                    {{ $t('confirm') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputTextArea from '@/components/Form/InputTextArea.vue';
import InputFile from '@/components/Form/InputFile.vue';
import toastr from 'toastr'
export default {
    name: "ModalApproveMultiPayment",
    emits: ['reload'],
    components: { InputTextArea, InputFile },
    props: {},
    data() {
        return {
            ids: [],
            errors: {},
        }
    },
    methods: {
        show(ids) {
            if(!ids){
                return;
            }
            this.ids = ids;
            window.jQuery(this.$refs.ModalApproveMultiPayment).modal('show');
        },
        hide() {
            this.ids = [];
            this.errors = {};
            window.jQuery(this.$refs.ModalApproveMultiPayment).modal('hide');
        },
        async confirm() {
            this.errors = {};
            const res = await this.$store.dispatch('payment/approveMultiPayment', {
                ids: this.ids
            });
            if(res?.result && !res?.result?.error){
                toastr.success(this.$t('msgUpdateSuccess'));
                this.$emit('reload');
            }else{
                toastr.error(this.$t('msgUpdateError'));
            }
            this.hide();
            return;
        },
    },
    computed: {
    }
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/Modal/ModalPaymentDetail.scss";
</style>
