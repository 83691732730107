<template>
    <div id="button-contact-vr">
        <div id="zalo-vr" class="button-contact">
            <div class="phone-vr">
                <div class="phone-vr-circle-fill"></div>
                <div class="phone-vr-img-circle">
                    <a target="_blank" :href="this.url_fanpage">
                        <img alt="Zalo" :src="this.messenger_png">
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import zalo_png from '../../../public/images/zalo.png'
import messenger_png from '../../../public/images/messenger.jpg'
export default {
    name: "Zalo",
    data(){
        return {
            zalo_png: zalo_png,
            messenger_png: messenger_png,
            url_fanpage: process.env.VUE_APP_URL_FANPAGE
        }
    },
    components: {},
    methods: {}
}
</script>
<style scoped lang="scss">
@import "../../assets/scss/Button/zalo.scss";
</style>