<template>
    <AppPermission :keyPerm="$constant.PERMISSIONS.PER_CONFIGS" :actionPerm="$constant.PERMISSIONS.PER_ACTION_LIST">
        <div id="dashboard-page" class="h-100vh">
            <div class='container-fluid p-3'>
                <div class='row'>
                    <div class='col-md-12'>
                        <div class='card'>
                            <div class='card-body'>
                                <div class='row'>
                                    <div class='col-xl-12 col-md-12 col-sm-12'>
                                        <a class="text-primary cursor-pointer" @click="syncProduct">1. {{$t('syncProduct')}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AppPermission>
</template>

<script>
import { checkPermission } from '@/utils/utils';
import i18n from '@/locales/i18nHelper' 
import { mapGetters } from 'vuex';
import AppPermission from '@/components/AppPermission.vue';
import toastr from 'toastr';

export default {
    name: 'Config',
    setup() {
        return { checkPermission };
    },
    components: {
        AppPermission
    },
    created() {
    },
    computed: {
        ...mapGetters({
            isLoading: 'isLoading',
            loadedPermission: 'loadedPermission'
        })
    },
    updated(){
    },
    data(){
        return {
            
        }
    },
    watch: {
        loadedPermission: {
            handler: function(newData) {
                if(newData && !this.isLoading){
                    if(!this.checkPermission(this.$constant.PERMISSIONS.PER_CONFIGS, this.$constant.PERMISSIONS.PER_ACTION_LIST)){
                        this.$router.push({name: this.$constant.ROUTER.dashboardName});
                    }
                }
            },
            deep: true
        },
    },
    methods: {
        async syncProduct(){
            const res = await this.$store.dispatch('configStore/syncProducts');
            if(res?.result){
                toastr.success(this.$t('actionSuccess'));
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/dashboard.scss";
</style>