<template>
    <aside class="left-sidebar" data-sidebarbg="skin5" id="left-sidebar">
        <div class="scroll-sidebar">
            <nav class="sidebar-nav">
                <ul id="sidebarnav" class="pt-4">
                    <template v-for="(item, index) in menus" :key="index">
                        <template v-if="item?.mobile">
                            <li class="sidebar-item sidebar-item-mobile d-block" :class="[(setClassName(item) === true ? ' selected' : '')]">
                                <a class="sidebar-link has-arrow waves-effect waves-dark" href="javascript:void(0)"
                                    aria-expanded="false">
                                    <i v-if="item?.icon">
                                        <img  :src="item?.icon" class="icon-menu"/>
                                    </i>
                                    <i class="mdi mdi-alert" v-else></i>
                                    <span class="hide-menu">{{ $t(item.label) }}</span>
                                </a>
                                <ul aria-expanded="false" class="collapse first-level">
                                    <li class="sidebar-item d-block" v-for="(child, idx) in item?.children"
                                        :key="'children_' + idx" :class="[(setClassName(child) === true ? ' selected' : '')]">
                                        <a class="sidebar-link" href="javascript:void(0)" @click="logout" v-if="child?.url == $constant.ROUTER.logout">
                                            <i class="mdi mdi-alert-octagon"></i>
                                            {{ $t('logout') }}
                                        </a>
                                        <router-link :to="child.url" class="sidebar-link" v-else>
                                            <i class="mdi mdi-alert-octagon"></i>
                                            <span class="hide-menu">{{ $t(child.label) }}</span>
                                        </router-link>

                                    </li>
                                </ul>
                            </li>
                        </template>

                        <template v-else>
                            <li class="sidebar-item" :class="[(setClassName(item) === true ? ' selected' : ''), (checkShowMenu(item) === true ? 'd-block' : 'd-none')]" v-if="!item?.children">
                                <router-link :to="item.url" class="sidebar-link waves-effect waves-dark sidebar-link"
                                    aria-expanded="false">
                                    <i v-if="item?.icon">
                                        <img  :src="item?.icon" class="icon-menu"/>
                                    </i>
                                    <i class="mdi mdi-view-dashboard" v-else></i>
                                    <span class="hide-menu">{{ $t(item.label) }}</span>
                                </router-link>
                            </li>

                            <template v-else>
                                <li class="sidebar-item" :class="[(setClassName(item) === true ? ' selected' : ''), (checkShowMenu(item) === true ? 'd-block' : 'd-none')]">
                                    <a class="sidebar-link has-arrow waves-effect waves-dark" href="javascript:void(0)"
                                        aria-expanded="false">
                                        <i v-if="item?.icon">
                                            <img  :src="item?.icon" class="icon-menu"/>
                                        </i>
                                        <i class="mdi mdi-alert" v-else></i>
                                        <span class="hide-menu">{{ $t(item.label) }}</span>
                                    </a>
                                    <ul aria-expanded="false" class="collapse first-level">
                                        <li class="sidebar-item" v-for="(child, idx) in item?.children"
                                            :key="'children_' + idx" :class="[(checkShowMenu(child) === true ? 'd-block' : 'd-none'), (setClassName(child) === true ? ' selected' : '')]">
                                            <router-link :to="child.url" class="sidebar-link">
                                                <i class="mdi mdi-alert-octagon"></i>
                                                <span class="hide-menu">{{ $t(child.label) }}</span>
                                            </router-link>
                                        </li>
                                    </ul>
                                </li>
                            </template>
                        </template>
                    </template>
                </ul>
            </nav>
        </div>
    </aside>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { checkRole, checkPermission } from '@/utils/utils';

export default defineComponent({
    name: 'Sidebar',
    props: ['menus', 'routePath'],
    components: {
    },
    setup(){
        return { checkRole, checkPermission }
    },
    data(){
        return {
            roleID: null,
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/userInfo'
        })
    },
    methods: {
        setClassName(item){
            // const selectMenu = item?.children?.filter((e) => e.url === this.routePath).length > 0
            const selectMenu = false;
            if(this.routePath === item?.url || selectMenu){
                return true;
            }else{
                return false;
            }
        },
        checkShowMenu(item){
            let show = false;
            if(item && item?.children && item.children?.length){
                item?.children.forEach(child => {
                    if(checkPermission(child.key, child.action)){
                        show = true;
                    }
                })
                return show;
            }
            if(checkPermission(item.key, item.action)){
                show = true;
            }
            return show;
        },
        async logout(){
            if(confirm(this.$t('logoutSystem'))){
                await this.$store.dispatch('auth/logout');
            }
        },
    },
    watch: {
        user(newValue){
            this.roleID = null;
            if(newValue && newValue?.role_id){
                this.roleID = newValue?.role_id;
            }
        }
    }
});
</script>
<style scoped lang="scss">
#left-sidebar{
    .icon-menu{
        width: 23px; 
        height: 25px; 
        filter: brightness(0) invert(1);
    }

    .sidebar-item-mobile{
        display: none !important;
    }
}
.show-sidebar {
    #left-sidebar {
        .sidebar-item-mobile{
            display: block !important;
        }
    }
}
</style>