import { Module } from 'vuex';
import http from '@/services/http';
import { Networks } from '@/services/Networks';

interface ConfigState {
}

const state: ConfigState = {
};

const configStore: Module<ConfigState, any> = {
    namespaced: true,
    state,
    mutations: {
    },
    actions: {
        async syncProducts({ commit }, params) {
            try {
                const response:any = await http.get(Networks.config.sync_products, {params: params});
                return response;
            } catch (error: any) {
                return {};
            }
        },
        async updatePermissions({ commit }, params) {
            try {
                const response:any = await http.get(Networks.config.update_permissions, {params: params});
                return response;
            } catch (error: any) {
                return {};
            }
        }
    },
    getters: {
    },
};

export default configStore;