<template>
    <li class="nav-item dropdown" id="notices__page" v-click-outside="showNotice">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
            aria-expanded="false" 
            @click.prevent="showNotice(!this.show)">
            <i class="mdi mdi-bell font-24" ></i>
            <span class="badge badge-pill notifications-counter-small" v-if="numberNotices">{{ numberFormat(numberNotices) }}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-right dropdown-notice" aria-labelledby="navbarDropdown" :class="[(show ? 'show' : '')]">
            <div class="px-3 d-flex justify-content-between border-bottom">
                <h5>
                    <label>{{ $t('notice') }}</label>
                </h5>
                <i class="cursor-pointer" @click="updateAllStatus" v-if="this.notices?.length > 0">{{ $t('readAll') }}</i>
            </div>
            <template v-if="this.notices?.length > 0">
                <li v-for="notice in this.notices" :key="notice">
                    <a class="dropdown-item" href="#" @click="updateStatus(notice.id)" v-if="notice.type == this.$constant.NOTICE.type_target_campaign">
                        <div class="notice-title" v-html="$t('titleNoticeTypeCampaign')"></div>
                        <div class="notice-desc">
                            <i18n-t keypath="contentNoticeTypeCampaign">
                                <template #name>
                                    <i v-text="notice?.target_campaign ? notice?.target_campaign?.name : null"></i>
                                </template>
                                <template #status>
                                    <b v-text="notice?.target_campaign ? $filters.formatStatusCampaignPartner(notice?.target_campaign?.status, notice?.target_campaign?.start_time, notice?.target_campaign?.end_time) : null"></b>
                                </template>
                                <template #start>
                                    <b v-text="notice?.target_campaign ? formatDateCTV(notice?.target_campaign?.start_time, 'DD/MM/YYYY') : null"></b>
                                </template>
                                <template #end>
                                    <b v-text="notice?.target_campaign ? notice?.target_campaign?.end_time ? formatDateCTV(notice?.target_campaign?.end_time, 'DD/MM/YYYY') : $t('unlimited') : null"></b>
                                </template>
                                <template #link>
                                    <a href="#" @click.stop="showDetailTargetCampaign(notice.src_id, notice.id)">{{ $t('atHere') }}</a>
                                </template>
                            </i18n-t>
                        </div>
                        <div class="notice-desc" v-html="timeAgo(notice.created_at)"></div>
                    </a>
                    <a class="dropdown-item" href="#" @click="updateStatus(notice.id)" v-if="notice.type == this.$constant.NOTICE.type_complete_payment">
                        <div class="notice-title" v-html="$t('titleNoticeTypeCompletePayment')"></div>
                        <div class="notice-desc">
                            <i18n-t keypath="contentNoticeTypeCompletePayment">
                                <template #start>
                                    <b v-text="notice?.complete_payment && notice?.complete_payment?.start_time ? formatDateCTV(notice?.complete_payment?.start_time, 'DD/MM/YYYY') : null"></b>
                                </template>
                                <template #end>
                                    <b v-text="notice?.complete_payment && notice?.complete_payment?.end_time ? formatDateCTV(notice?.complete_payment?.end_time, 'DD/MM/YYYY') : null"></b>
                                </template>
                                <template #code>
                                    <b v-text="notice.code"></b>
                                </template>
                                <template #amount>
                                    <b v-text="numberFormat(notice?.complete_payment?.amount)"></b>
                                </template>
                            </i18n-t>
                        </div>
                        <div class="notice-desc" v-html="timeAgo(notice.created_at)"></div>
                    </a>
                    <a class="dropdown-item" href="#" @click="updateStatus(notice.id)" v-if="notice.type == this.$constant.NOTICE.type_reject_payment">
                        <div class="notice-title" v-html="$t('titleNoticeTypeRejectPayment')"></div>
                        <div class="notice-desc">
                            <i18n-t keypath="contentNoticeTypeRejectPayment">
                                <template #start>
                                    <b v-text="notice?.reject_payment && notice?.reject_payment?.start_time ? formatDateCTV(notice?.reject_payment?.start_time, 'DD/MM/YYYY') : null"></b>
                                </template>
                                <template #end>
                                    <b v-text="notice?.reject_payment && notice?.reject_payment?.end_time ? formatDateCTV(notice?.reject_payment?.end_time, 'DD/MM/YYYY') : null"></b>
                                </template>
                                <template #code>
                                    <b v-text="notice.code"></b>
                                </template>
                                <template #reason>
                                    <b v-text="notice?.reject_payment?.reason"></b>
                                </template>
                            </i18n-t>
                        </div>
                        <div class="notice-desc" v-html="timeAgo(notice.created_at)"></div>
                    </a>
                    <a class="dropdown-item" href="#" @click="updateStatus(notice.id)" v-if="notice.type == this.$constant.NOTICE.type_approved_payment">
                        <div class="notice-title" v-html="$t('titleNoticeTypeApprovedPayment')"></div>
                        <div class="notice-desc">
                            <i18n-t keypath="contentNoticeTypeApprovedPayment">
                                <template #start>
                                    <b v-text="notice?.approved_payment && notice?.approved_payment?.start_time ? formatDateCTV(notice?.approved_payment?.start_time, 'DD/MM/YYYY') : null"></b>
                                </template>
                                <template #end>
                                    <b v-text="notice?.approved_payment && notice?.approved_payment?.end_time ? formatDateCTV(notice?.approved_payment?.end_time, 'DD/MM/YYYY') : null"></b>
                                </template>
                                <template #code>
                                    <b v-text="notice.code"></b>
                                </template>
                                <template #amount>
                                    <b v-text="numberFormat(notice?.approved_payment?.amount)"></b>
                                </template>
                            </i18n-t>
                        </div>
                        <div class="notice-desc" v-html="timeAgo(notice.created_at)"></div>
                    </a>
                </li>
            </template>

            <li id="noData" v-if="this.notices?.length == 0">
                <span>{{ $t('noData') }}</span>
            </li>
        </ul>
    </li>
</template>

<script>
import { numberFormat, formatDateCTV } from '@/utils/utils';
import { mapGetters } from 'vuex';
import { formatDistanceToNow } from 'date-fns';
export default {
    name: 'Notice',
    components: {},
    setup(){
        return {
            numberFormat, formatDateCTV
        }
    },
    data() {
        return {
            show: false
        }
    },
    mounted(){
        this.$store.dispatch('noticeStore/getNumberNoticeInActive')
    },
    computed: {
        ...mapGetters({
            notices: 'noticeStore/getNotices',
            numberNotices: 'noticeStore/getNumberNotices',
            user: 'auth/userInfo'
        })
    },
    methods: {
        showDetailTargetCampaign(id, notice_id) {
            this.updateStatus(notice_id);
            if(this.user && this.user.type == this.$constant.PARTNER.type_partner){
                this.$router.push({name: this.$constant.ROUTER.detailTargetCampaignPartnerName, query: {id: id}});
            }else if(this.user && this.user.type == this.$constant.PARTNER.type_colloborator){
                this.$router.push({name: this.$constant.ROUTER.detailCollaboratorTargetCampaignName, params: {id: id}});
            }else{
                this.$router.push({name: this.$constant.ROUTER.detailTargetCampaignName, query: {id: id}});
            }
        },
        async updateStatus(id){
            this.showNotice();
            await this.$store.dispatch('noticeStore/updateStatusNotice', {notice_id: id})
            await this.$store.dispatch('noticeStore/getNumberNoticeInActive')
        },
        async updateAllStatus(){
            this.showNotice();
            await this.$store.dispatch('noticeStore/updateAllStatusNotice')
            await this.$store.dispatch('noticeStore/getNumberNoticeInActive')
            await this.$store.dispatch('noticeStore/clearNotice')
        },
        showNotice(show = false){
            this.show = show;
            if(this.show && this.numberNotices){
                this.getListNotice();
            }
        },
        async getListNotice(){
            await this.$store.dispatch('noticeStore/getListNotices')
        },
        timeAgo(date) {
            return formatDistanceToNow(new Date(date), { addSuffix: true });
        }
    },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/notice.scss";
</style>