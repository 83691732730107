<template>
    <div id="payment-page">
        <div class='container-fluid p-3'>
            <div class='row mb-3'>
                <div class='row'>
                    <div class="col-xl-2 col-md-3 col-sm-12">
                        <InputText :placeholder="$t('requestPaymentCode')" v-model.trim="filters.keyword"/>
                    </div>
                    <div class="col-xl-2 col-md-3 col-sm-12" v-if="this.user?.type != $constant.PARTNER.type_colloborator">
                        <InputText :placeholder="$t('partnerOrContributorName')" v-model.trim="filters.name"/>
                    </div>
                    <div class="col-xl-2 col-md-3 col-sm-12">
                        <Daterangepicker :placeholder="$t('requestPaymentDate')" v-model="filters.time"/>
                    </div>

                    <div class="col-xl-2 col-md-3 col-sm-12">
                        <button type='submit' class='btn btn-success' @click="search">
                            <span>
                                {{ $t('search') }}
                            </span>
                        </button>
                        <button type='submit' class='btn btn-secondary mx-2' @click="clearFilter">
                            <span>
                                {{ $t('clearFilter') }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-lg-12'>
                    <div class="card">
                        <div class="card-body px-0 d-flex flex-wrap ">
                            <div class='col-xl-9 col-md-8 col-sm-12 container-status'>
                                <template v-for="(status, index) in statusLists" :key="index">
                                    <button class="btn"
                                        :class="status.id === filters.status ? 'border-bottom__custom' : ''"
                                        @click="changeFilterStatus(status.id)">
                                        <span class="card-title">
                                            {{ $t(status.label) }}
                                        </span>
                                    </button>
                                </template>
                            </div>

                            <div class="col-xl-3 col-md-4 col-sm-12 text-right">
                                <AppPermission :keyPerm="$constant.PERMISSIONS.PER_PAYMENT_MANAGE" :actionPerm="$constant.PERMISSIONS.PER_ACTION_UPDATE">
                                    <button type='submit' class='btn btn-success mx-2' @click="showModalApproveMultiPayment" 
                                    v-if="this.multiplePayment && this.multiplePayment?.length && (!this.user?.type || this?.user?.type == this.$constant.PERMISSIONS.TYPE_ADMIN)">
                                        <span>
                                            {{ $t('approve') }}
                                        </span>
                                    </button>
                                </AppPermission>
                                <AppPermission :keyPerm="$constant.PERMISSIONS.PER_PAYMENT_MANAGE" :actionPerm="$constant.PERMISSIONS.PER_ACTION_UPDATE">
                                    <button type='submit' class='btn btn-success mx-2' @click="showModalImportPayment" 
                                    v-if="this?.user?.type == this.$constant.PERMISSIONS.TYPE_ACCOUNTANT">
                                        <span>
                                            {{ $t('importData') }}
                                        </span>
                                    </button>
                                </AppPermission>
                                <button @click="exportData()" class="btn btn-outline-secondary">
                                    <span>{{ $t('exportData') }}</span>
                                </button>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="thead-light border">
                                    <tr>
                                        <th v-if="(!this.user?.type || this?.user?.type == this.$constant.PERMISSIONS.TYPE_ADMIN) && filters.status == this.$constant.PAYMENT.status.wait_for_approve">
                                            <input @click=onCheckAll(!checkedAll) :checked="checkedAll" type="checkbox"/>
                                        </th>
                                        <th scope="col">{{ $t('requestPaymentCode') }}</th>
                                        <th scope="col" v-if="this.user?.type != $constant.PARTNER.type_colloborator">{{ $t('partnerOrContributorName') }}</th>
                                        <th scope="col">{{ $t('createdAtTicket') }}</th>
                                        <th scope="col">{{ $t('processingAt') }}</th>
                                        <th scope="col">{{ $t('price') }}</th>
                                        <th scope="col">{{ $t('requestPaymentNote') }}</th>
                                        <th scope="col">{{ $t('status') }}</th>
                                        <th scope="col">{{ $t('action') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable" v-if="this.entries?.length > 0">
                                    <template v-for="(entry, index) in entries" :key="index">
                                        <tr>
                                            <td v-if="(!this.user?.type || this?.user?.type == this.$constant.PERMISSIONS.TYPE_ADMIN) && filters.status == this.$constant.PAYMENT.status.wait_for_approve">
                                                <input type="checkbox" v-model="multiplePayment" :value="entry.id"/>
                                            </td>
                                            <td v-text="entry.code"></td>
                                            <td v-text="entry.partner_name" v-if="this.user?.type != $constant.PARTNER.type_colloborator"></td>
                                            <td v-text="entry.created_at ? formatDateCTV(entry.created_at, 'DD/MM/YYYY') : null"></td>
                                            <td v-text="entry.payment_date ? formatDateCTV(entry.payment_date, 'DD/MM/YYYY') : null"></td>
                                            <td v-text="numberFormat(entry.amount)"></td>
                                            <td v-text="entry.content_payment"></td>
                                            <td>
                                                <span v-text="entry?.statusName" :class="getClassPaymentStatus(entry.status)"></span>
                                            </td>
                                            <td>
                                                <span class="cursor-pointer" @click="showModalPaymentDetail(entry)">
                                                    <span class="icon_tooltip" :titleCustom="$t('showDetail')"><i class="fas fa-eye text-active mx-2"></i></span>
                                                </span>
                                                <AppPermission :keyPerm="$constant.PERMISSIONS.PER_PAYMENT_MANAGE" :actionPerm="$constant.PERMISSIONS.PER_ACTION_UPDATE">
                                                    <span class="cursor-pointer" @click="showModalPaymentDetail(entry, true)" v-if="checkCanApprove(entry.status, this.user, entry.partner_id)">
                                                        <span class="icon_tooltip" :titleCustom="$t('waitForApprove')"><i class="far fa-edit text-active mx-2"></i></span>
                                                    </span>
                                                    <span class="cursor-pointer" @click="showModalPaymentDetail(entry, true)" v-if="checkCanPayment(entry.status, this.user, entry.partner_id)">
                                                        <span class="icon_tooltip" :titleCustom="$t('waitForPayment')"><i class="far fa-edit text-active mx-2"></i></span>
                                                    </span>
                                                </AppPermission>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                            <div id="noData" v-if="this.entries?.length == 0">
                                <span>{{ $t('noData') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Paginate :value="paginate" :record="record" v-if="this.entries?.length > 0" @pageChange="pageChange"/>
        </div>
        <ModalPaymentDetail :action="this.action" ref="ModalPaymentDetail" @reload="loadData"/>
        <ModalImportPayment ref="ModalImportPayment" @reload="loadData"/>
        <ModalApproveMultiPayment ref="ModalApproveMultiPayment" @reload="loadData(true)"/>
        <Loading :loading="isLoading" />
    </div>
</template>

<script>
import Daterangepicker from '@/components/Daterangepicker.vue'
import { numberFormat, getStatusPaymentName, formatDateCTV, checkPermission, checkCanPayment, checkCanApprove, getClassPaymentStatus, selectedElement } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import InputText from '@/components/Form/InputText.vue';
import Paginate from '@/components/Paginate.vue';
import SwitchButton from '@/components/Button/SwitchButton.vue';
import ModalPaymentDetail from '@/components/Modal/Payment/ModalPaymentDetail.vue'
import { mapGetters } from 'vuex';
import AppPermission from '@/components/AppPermission.vue';
import ModalImportPayment from '@/components/Modal/Payment/ModalImportPayment.vue'
import ModalApproveMultiPayment from '@/components/Modal/Payment/ModalApproveMultiPayment.vue'

export default {
    name: 'Payment',
    setup() {
        return { numberFormat, getStatusPaymentName, formatDateCTV, checkPermission, checkCanPayment, checkCanApprove, getClassPaymentStatus, selectedElement };
    },
    components: {
        Daterangepicker,
        Loading,
        InputText,
        Paginate,
        SwitchButton,
        ModalPaymentDetail,
        AppPermission,
        ModalImportPayment,
        ModalApproveMultiPayment
    },
    data() {
        return {
            isLoading: false,
            record: 100,
            paginate: {
                totalRecord: 1,
                currentPage: 1,
                lastPage: 1
            },
            statusLists: [
                { id: 0, label: 'all' },
                { id: this.$constant.PAYMENT.status.wait_for_approve, label: 'waitForApprove' },
                { id: this.$constant.PAYMENT.status.wait_for_payment, label: 'waitForPayment' },
                { id: this.$constant.PAYMENT.status.paid, label: 'paid' },
                { id: this.$constant.PAYMENT.status.reject, label: 'rejectPayment' },
            ],
            filters: {
                status: 0,
                record: 25,
            },
            action: 1,
            multiplePayment: [],
            checkedAll: false
        }
    },
    mounted(){
        this.loadData();
    },
    computed: {
        ...mapGetters({
            entries: 'payment/getPaymentLists',
            user: 'auth/userInfo',
            isLoading: 'isLoading',
            loadedPermission: 'loadedPermission'
        })
    },
    updated(){
        if(!this.isLoading && this.loadedPermission){
            if(!this.checkPermission(this.$constant.PERMISSIONS.PER_PAYMENT_MANAGE, this.$constant.PERMISSIONS.PER_ACTION_LIST)){
                this.$router.push({name: this.$constant.ROUTER.dashboardName});
            }
        }
    },
    methods: {
        changeFilterStatus(status) {
            this.filters.status = status;
            this.loadData();
        },
        async loadData(clearMultiplePayment = false) {
            if(clearMultiplePayment){
                this.multiplePayment = [];
            }
            const res = await this.$store.dispatch('payment/fetchPaymentInfo', this.filters);
            this.paginate.totalRecord = res?.result?.data?.total;
            this.paginate.currentPage = res?.result?.data?.current_page;
            this.paginate.lastPage = res?.result?.data?.last_page;
            this.checkSelectedAll();
        },
        openDetailPayment(status){
            this.action = status
        },
        search(){
            this.filters.page = 1;
            this.loadData();
        },
        clearFilter(){
            this.filters = {
                status: 0,
                record: 25,
                contributor_name: null,
                keyword: null,
                time: null,
                name: null
            },
            this.loadData();
        },
        showModalPaymentDetail(entry, edit = false){
            this.$refs.ModalPaymentDetail.show(entry, edit);
        },
        async exportData() {
            if (this?.user?.type == this.$constant.PERMISSIONS.TYPE_CONTRIBUTOR) {
                this.filters.name_export = this?.user?.name
            }
            const res = await this.$store.dispatch('payment/exportPayment', this.filters);
            if (res) {
                this.$store.dispatch('setShowModalExport', true)
            }
        },
        showModalImportPayment(){
            this.$refs.ModalImportPayment.show();
        },
        checkSelectedAll(){
            if(!this.entries || !this.entries?.length){
                this.checkedAll = false;
                return;
            }
            let checked = true;
            this.entries.forEach(entry => {
                if(!this.multiplePayment.includes(entry?.id)){
                    checked = false;
                    this.checkedAll = checked;
                    return;
                }
            });
            this.checkedAll = checked;
        },
        onCheckAll(status) {
            this.checkedAll = status;
            this.entries.forEach(entry => {
                this.multiplePayment = this.selectedElement(this.multiplePayment, entry.id, status);
            });
        },
        pageChange(page) {
            this.filters.page = page
            this.loadData()
        },
        showModalApproveMultiPayment(){
            this.$refs.ModalApproveMultiPayment.show(this.multiplePayment);
        },
    },
    watch: {
        multiplePayment: {
            handler: function(newData) {
                this.checkSelectedAll();
                this.$forceUpdate();
            },
            deep: true
        },
    }
};
</script>

<style lang="scss" scoped>
// @import "../../assets/scss/partner.scss";
</style>