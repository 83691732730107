<template>
    <div class='modal fade' ref="ModalImportPayment" id='ModalImportPayment' aria-labelledby='ModalImportPayment'
        aria-hidden='true' data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-md'>
            <div class='modal-content'>
                <div class='modal-header'>
                    <h1 class='modal-title fs-5'>{{ $t('titleImportPayment') }}</h1>
                    <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                </div>
                <div class='modal-body'>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12 mb-2'>
                            <div>
                                <label>{{ $t('templateExcel') }}</label>
                            </div>
                            <a class="btn btn-primary" :href="'https://files.omicare.vn/admin/03-2025/Import_Template_Payment.xlsx'">
                                <i class="fa fa-download mx-1"></i>
                                <span>{{ $t('downloadTemplate') }}</span>
                            </a>
                        </div>
                        <div class='col-lg-12 col-md-12 col-sm-12'>
                            <div>
                                <label>{{ $t('importPayment') }}</label>
                            </div>
                            <input @change="fileChanged" type='file' class="d-none" ref="inputUpload"
                                :accept="'xlsx'" />
                            <button class="btn btn-outline-secondary" @click="clickUpload">
                                <span>{{ $t('selectFile') }}</span>
                            </button>
                            <span class="text-primary px-2">
                                {{ this.fileName }}
                            </span>
                        </div>
                        <div class='col-lg-12 col-md-12 col-sm-12'>
                            <ul class="error-label" v-if="errors && errors.length">
                                <li v-for="error in errors" :key="error" v-text="error"></li>
                            </ul>
                        </div>
                    </div>
                    <div class='row mt-3'>
                        <div class='d-flex justify-content-center mt-3'>
                            <button class='btn btn-secondary mx-2' data-bs-dismiss='modal' aria-label='Close'>
                                {{ $t('cancel') }}
                            </button>
                            <button class='btn btn-warning mx-2' @click="upload">
                                <span>
                                    {{ $t('upload') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { clone } from '@/utils/utils';
import InputTextArea from '@/components/Form/InputTextArea.vue';
import InputFile from '@/components/Form/InputFile.vue';
import toastr from 'toastr'
export default {
    name: "ModalImportPayment",
    emits: ['reload'],
    components: { InputTextArea, InputFile },
    props: {},
    data() {
        return {
            entry: {},
            errors: {},
            fileName: null,
            file: {}
        }
    },
    methods: {
        show() {
            window.jQuery(this.$refs.ModalImportPayment).modal('show');
        },
        hide() {
            this.entry = {};
            this.errors = {};
            this.fileName = null;
            window.jQuery(this.$refs.ModalImportPayment).modal('hide');
        },
        clickUpload() {
            this.$refs.inputUpload.value = '';
            this.$refs.inputUpload.click();
        },
        async fileChanged(event) {
            const file = event.target.files[0];
            this.fileName = file.name;
            this.file = file;
        },
        checkTypeFile(file){
            if(!file){
                return false;
            }
            this.errors = [];
            if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                return true;
            } else {
                this.errors = [this.$t('invalidFormatFile')];
                return false;
            }
        },
        async upload() {
            if(!this.checkTypeFile(this.file)){
                return;
            }
            this.errors = {};
            const res = await this.$store.dispatch('payment/importPayment', this.file);
            if(res?.result?.code == 200){
                toastr.success(this.$t('msgUpdateSuccess'));
                this.$emit('reload');
            }else{
                toastr.error(this.$t('msgUpdateError'));
            }
            this.hide();
            return;
        },
    },
    computed: {
    }
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/Modal/ModalPaymentDetail.scss";
</style>
